import React from 'react';
import { enquireScreen } from 'enquire-js';

export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>互播课堂平台</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <>
            <p>
              通过高效实时视频互动通信，视频直播，视频点播，组织管理，SaaS云服务等技术手段，整合智能设备（手机，平板，会议平板，智能盒子，PC），为教育培训/政府机构，提供远程学习教育
              的解决方案平台。
            </p>
          </>
        ),
      },
    ],
  },
  img: {
    children:
      'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/product-banner.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <>
              <p>大班课</p>
            </>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <>
              <p>
                1个老师默认连麦进入教室，学生进入无限制人数进入教室。百万并发低延时，适合大课堂场景使用，支持老师与学员实时举手互动。
              </p>
            </>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <>
              <p>小班课</p>
            </>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <>
              <p>
                1个老师和至多16个学生默认视频连麦互动进入教室。精修小班课，适合强互动场景教学。
              </p>
            </>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <>
              <p>1v1课堂</p>
            </>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <>
              <p>
                1个老师和1个学生默认视频连麦互动进入教室。适合一对一咨询，精准课程辅导场景使用。
              </p>
            </>
          ),
        },
      },
    ],
  },
};
export const Pricing20DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>互播课堂功能详情</p>
          </>
        ),
        className: 'pricing2-title-h1',
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'name',
          key: 'name',
          name: 'empty',
          childWrapper: {
            children: [
              { name: 'name', children: ' ' },
              { name: 'content', children: ' ' },
            ],
          },
        },
        {
          dataIndex: 'basic',
          key: 'basic',
          name: 'basic',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <>
                    <p>学生端</p>
                  </>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money k7eb4qik589-editor_css',
                children: (
                  <>
                    <p>
                      Android App，<span>IOS App</span>
                    </p>
                    <p>
                      Windows客户端，<span>Mac客户端</span>
                    </p>
                    <p>Web网站</p>
                  </>
                ),
              },
              {
                name: 'button',
                children: {
                  href: '#',
                  children: '立即购买',
                  className: 'k7eb8t8yr4r-editor_css',
                },
              },
            ],
          },
        },
        {
          dataIndex: 'pro',
          key: 'pro',
          name: 'pro',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <>
                    <p>老师端</p>
                  </>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money k7eb58yxxw-editor_css',
                children: (
                  <>
                    <p>
                      Windows客户端，<span>Mac客户端</span>
                    </p>
                    <p>Web网站</p>
                  </>
                ),
              },
              {
                name: 'button',
                children: {
                  href: '#',
                  type: 'primary',
                  children: '立即购买',
                  className: 'k7eb4mfdqqi-editor_css',
                },
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂管理-创建课堂</p>
                </>
              ),
            },
            {
              children: '-',
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              name: 'content2',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂管理-加入课堂</p>
                </>
              ),
            },
            {
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              name: 'content2',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>互动白板-书写批注</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list3',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>互动白板-图形</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list4',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>互动白板-文字录入</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list5',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>互动白板-内容圈选</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7atkxl32zl',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>互动白板-颜色选取</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7atkyipt9n',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>互动白板-擦除</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7atkz86qme',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>互动白板-白板拖动</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7atl07hn3u',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>互动白板-白板缩放</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7atl0t7oy',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>互动白板-白板增页（无限制）</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eauyhbcd7',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>文件/课件-图片上传</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eauz2z0k9',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>文件/课件-演示文稿上传（pptx转网页）</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eauzpmdnp',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>文件/课件-文档上传（ppt，pptx，word，pdf转图片）</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eav0bdl5',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>文件/课件-文件管理/文件切换</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eav1gjemt',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>文件/课件-音频/视频上传（mp3，mp4）</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eav1zxjur',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>文件/课件-课件观看</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eav2h6bwf',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>共享屏幕（共享显示器设备）</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eav2z85el',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>共享应用程序窗口（打开ppt，word等应用程序）</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eav6ct3sd',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>即时通讯-文字聊天</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eav6zhi9i',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>即时通讯-全员禁言</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eayrx50p',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>即时通讯-指定学员禁言</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eayskfwah',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂计时-开启课堂计时</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eaythntvh',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂计时-结束课堂计时</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eaytzqvjl',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂计时-查看课堂计时</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eayuurtaa',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂管理-关闭/开启老师端摄像头</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eb6d69wvo',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂管理-关闭/开启老师端麦克风</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eb6dntsvq',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂管理-关闭/开启学生端摄像头</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7eb6e3wl2f',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂管理-学生管理</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7ebcbpf3fq',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂管理-指定学员禁言</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7ebcc68aw',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂管理-查看课堂学生人数</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7ebccpwi5e',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂管理-审批学员举手</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7ebcdi2gt',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>课堂管理-关闭互动学员视频窗口</p>
                </>
              ),
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list~k7ebcdxnrz',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <>
                  <p>护眼模式</p>
                </>
              ),
            },
            {
              name: 'content1',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/14ce3060-34e6-4b30-9a45-1a6b95542310.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper k7au0vi4dd9-editor_css',
    playScale: 0.3,
    replay: false,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <>
            <p>适配多终端学习场景的专属线上课堂</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <>
            <p>
              支持Windows 、macOS 、Web PC 、Android App 、iOS App 、H5网站。
            </p>
          </>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <>
            <p>7*24小时 专属客户经理 为您服务</p>
          </>
        ),
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        href: 'https://r.xiumi.us/stage/v5/2Wv8U/197237249',
        children: (
          <>
            <p>免费体验</p>
          </>
        ),
      },
    },
  },
};
