import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/Group.png',
  },
  Menu: {
    className: 'header3-menu k7atxq51dib-editor_css',
    children: [
      { name: 'item0', className: 'header3-item',a: { children: (<> <p>首页</p></>),  className: 'header3-item-block', name: 'text',href: '/' } },
      { name: 'item1', className: 'header3-item',a: { children: '功能介绍', className: 'header3-item-block', name: 'text', href: '/function' } },
      { name: 'item2', className: 'header3-item',a: { children: '电脑客户端', className: 'header3-item-block', name: 'text', href: '/pcdownload' } },
      { name: 'item3', className: 'header3-item',a: { children: '手机客户端',  className: 'header3-item-block', name: 'text',href: '/appdownload' } },
    ],
    // children: [
    //   {
    //     name: 'item0',
    //     className: 'header3-item',
    //     children: {
    //       href: '#',
    //       children: [
    //         {
    //           children: (
    //             <>
    //               <p>首页</p>
    //             </>
    //           ),
    //           name: 'text',
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     name: 'item1',
    //     className: 'header3-item',
    //     a: {
    //       href: '/abc',
    //       children: [
    //         {
    //           children: (
    //             <>
    //               <p>功能介绍</p>
    //             </>
    //           ),
    //           name: 'text',
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     name: 'item2',
    //     className: 'header3-item',
    //     a: {
    //       href: '#',
    //       children: [
    //         {
    //           children: (
    //             <>
    //               <p>电脑客户端</p>
    //             </>
    //           ),
    //           name: 'text',
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     name: 'item3',
    //     className: 'header3-item',
    //     a: {
    //       href: '#',
    //       children: [
    //         {
    //           children: (
    //             <>
    //               <p>手机客户端</p>
    //             </>
    //           ),
    //           name: 'text',
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     name: 'item~k7die0o0qp',
    //     className: 'header3-item',
    //     a: {
    //       href: '#',
    //       children: [
    //         {
    //           children: (
    //             <>
    //               <p>互播课堂</p>
    //             </>
    //           ),
    //           name: 'text',
    //         },
    //       ],
    //     },
    //   },
    // ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner40DataSource = {
  wrapper: { className: 'home-page-wrapper banner4' },
  page: { className: 'home-page banner4-page' },
  childWrapper: {
    className: 'banner4-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>WISSO 互播™ 课堂</p>
          </>
        ),
        className: 'banner4-title k7dr5ffh7am-editor_css',
      },
      {
        name: 'content',
        className: 'banner4-content',
        children: (
          <>
            <p>专注提升教育品质&nbsp; 让知识传播无距离</p>
          </>
        ),
      },
      {
        name: 'button',
        children: { href: 'https://huboedu.wisso.cn', type: 'primary', children: '体验PC网页版' , target: '_blank'},
      },
    ],
  },
  image: {
    className: 'banner4-image',
    children:
      'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/cover.png',
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>在线课堂教育解决方案提供者</p>
          </>
        ),
      },
      {
        name: 'title~k7dmxjile37',
        className: 'k7dmxkf9jg-editor_css',
        children: (
          <>
            <p>用科技为老师与教育机构赋能</p>
          </>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/xueke.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>全学科覆盖</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>
                    <span>开放设计，满足不同学科、教育领域教学需求</span>
                    <br />
                  </p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/changjing.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>全场景支持</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>丰富班型，探索全新教学模式</p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/hudong.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>深度沉浸互动</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>备教学练实时互动，不仅是视频直播工具</p>
                </>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper k7e44nal1r-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/edu.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <>
        <p>更了解在线教育创业者</p>
      </>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>
          不用购买服务器等硬件，也不用为维护硬件支付额外成本，更不用招募研发团队进行产品设计和开发，一键即可拥有一套完整在线教学课堂系统。
        </p>
        <p>让您更专注核心业务，快速稳健成长。</p>
      </>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper k7dqwahh7ta-editor_css',
  },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/xiaoban.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <>
        <p>小班课：1v1、1v16双向画面</p>
      </>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <>
        <p>
          互播课堂支持大班课、小班课（含1v1、1vN）等多种教学场景，实现师生两边直播画面传输，具备高稳定、低延迟、高清画质等技术优势，同时课堂还搭配了多种教学互动功能，打破地域空间限制，让学生享受到优质的教学服务，真实还原现实教学场景。
        </p>
      </>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper k7e45ai6yb-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: {
    className: 'content1-img k7e4xqqhya-editor_css',
    md: 10,
    xs: 24,
  },
  img: {
    children:
      'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/daketang.png',
    className: 'k7e4vh7wtii-editor_css',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <>
        <p>大班课：高并发万人在线的大课堂</p>
      </>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>
          适用于大型宣讲/教学为主的互动授课方式，助力机构开展线上讲座，支持1V1学员/嘉宾连麦视频，万人在线不卡顿，让大班课更便捷，满足大型线上直播教学场景。
        </p>
      </>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper k7dptp0q6f-editor_css',
  },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/hudongjiaoju.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <>
        <p>
          教学工具&nbsp; &nbsp;<span>还原现场</span>
        </p>
      </>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <>
        <p>多维教学工具，动态展示教学内容，所见即所得，高效传递知识信息。</p>
        <p>
          全平台、全球互联的实时互动白板，延时低、高性能，<span>
            支持导入多媒体信息。
          </span>
        </p>
      </>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper k7e45pk2v3g-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/kejian.jpg',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <>
        <p>互动课件&nbsp;自由切换</p>
      </>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>
          支持所有通用文档（图片，文档，音频，视频），<span>
            多向屏幕分享，
          </span>
          <span>文档协同编辑批注，I</span>
          <span>M即时通讯。</span>
          <span>让教学可以在各类文档之间跳转。</span>
        </p>
      </>
    ),
  },
};
export const Feature51DataSource = {
  wrapper: {
    className: 'home-page-wrapper content7-wrapper k7dpx0vjawo-editor_css',
  },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>多课堂类型 全场景教学</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <>
            <p>致力为教育机构提供优质智慧线上课堂解决方案</p>
          </>
        ),
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <>
                <p>大课堂</p>
              </>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <>
                <span>
                  <h3>全平台覆盖</h3>
                  <p>
                    支持Windows客户端、macOS客户端、Android App、iOS
                    App、Web网站、小程序等多平台覆盖。
                  </p>
                  <br />
                  <h3>大型直播&nbsp; 百万并发</h3>
                  <p>
                    万人大课堂，支持学生举手提问，与老师视频互动教学。海量并发高可用，超强抗丢包、抗抖动效果。
                  </p>
                  <br />
                  <h3>
                    全球部署
                  </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
                </span>
              </>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/pic-daban.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'tablet' },
          text: {
            className: 'content7-tag-name',
            children: (
              <>
                <p>1对N小班课</p>
              </>
            ),
          },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <>
                <span>
                  <h3>小班课&nbsp;1对16强互动</h3>
                  <p>
                    老师和多名学生，实时视频互动教学，老师实时掌握教学反馈。
                  </p>
                  <br />
                  <h3>低延时 高清画质</h3>
                  <p>
                    500ms
                    低延迟、高清画质，多平台支持，全球范围直播授课，多教室同步监管，轻触即可解决网课问题，随时随地解决网上授课问题。
                  </p>
                  <br />
                  <h3>互动白板</h3>
                </span>全平台、全球互联的实时互动白板，延时低、性能好，并且支持导入通用文档（Word，Pdf，PPT，Mp3，Mp4，jpg，png）<span
                >
                  <br />
                </span>
              </>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/pic-xiaoban.png',
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <>
                <p>1对1辅导教学</p>
              </>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <>
                <span>
                  <h3>双流1080P</h3>
                  <p>
                    老师与学生双向1080P视频高清传输，500ms低延时实时1对1深度互动教学。
                  </p>
                  <br />
                  <h3>课堂权限管理</h3>
                  <p>
                    可控制学生发言，摄像头图像采集，麦克风声音采集，使用教学工具的权限。
                  </p>
                  <br />
                  <h3>管理控制台</h3>
                  <p>
                    可以通过控制台对老师，学生账户授权，权限等所有服务进行中心化管理。
                  </p>
                </span>
              </>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/pic-1-1.png',
          },
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>互播课堂提供专业的服务</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <>
            <p>基于WISSO互播云服务提供强大支撑</p>
          </>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>丰富班型</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>
                  一对一，<span>小班课，</span>
                  <span>大班课，</span>
                  <span>双师课堂，</span>
                  <span>网页大直播，</span>
                  <span>会场直播。</span>
                </p>
              </>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>贯穿教学</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>
                  课堂管理，<span>云盘分享，</span>
                  <span>多向屏幕分享，</span>
                  <span>文档协同编辑，I</span>
                  <span>M即时通讯。</span>
                </p>
              </>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>超清画质、高音质</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>支持1080P 60FPS 超清视频，48kHz全频带音频编码。</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>全平台覆盖</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>
                  支持Windows客户端、macOS客户端、Android App、iOS
                  App、Web网站、小程序等多平台覆盖。
                </p>
              </>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>定制服务</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>
                  简单对接，轻松定制教育机构自有品牌专属线上课堂，课中数据一键同步，数据更安全效果更可控。
                </p>
              </>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>弹性可扩展</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>
                  授权用户数量灵活扩展，云服务流量高峰弹性计算，保证服务稳定，<span
                  >
                    支持百万人超大频道实时互动。
                  </span>
                </p>
              </>
            ),
          },
        },
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <>
            <p>适配多终端学习场景的专属线上课堂</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <>
            <p>
              <span>
                支持Windows&nbsp; 、macOS&nbsp; 、Web PC 、Android App 、iOS App
                、H5网站。
              </span>
              <br />
            </p>
          </>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <>
            <p>7*24小时 专属客户经理 为你服务</p>
          </>
        ),
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        href: 'https://r.xiumi.us/stage/v5/2Wv8U/197237249',
        children: (
          <>
            <p>免费体验</p>
          </>
        ),
      },
    },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/logofoot.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <>
                  <p>Copy right@2020 - 至今&nbsp;</p>
                  <p>
                    WISSO&nbsp;<span>深圳市威騪智能科技有限公司</span>
                  </p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <>
              <p>中和黄埔/威騪WISSO</p>
            </>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: 'http://www.hopechina.cc/pages/solution/eus.html',
              children: (
                <>
                  <p>企业大学解决方案</p>
                </>
              ),
              target: '_blank',
            },
            {
              name: 'link1',
              href:
                'http://www.hopechina.cc/WBAofficialWebsite/WBAofficialWebsite.html',
              children: (
                <>
                  <p>商协会系统解决方案</p>
                </>
              ),
              target: '_blank',
            },
            {
              name: 'link2',
              href: 'http://www.hopechina.cc/pages/beishuang/beishuang.html',
              children: (
                <>
                  <p>视频会议</p>
                </>
              ),
              target: '_blank',
            },
            {
              name: 'link3',
              href: 'http://www.hopechina.cc/pages/product/businesstrain.html',
              children: (
                <>
                  <p>企业内训</p>
                </>
              ),
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            {
              href: 'http://www.hopechina.cc/pages/aboutus/companyprofile.html',
              name: 'link0',
              children: (
                <>
                  <p>公司简介</p>
                </>
              ),
              target: '_blank',
            },
            {
              href:
                'http://www.hopechina.cc/pages/aboutus/corporateculture.html',
              name: 'link1',
              children: (
                <>
                  <p>企业文化</p>
                </>
              ),
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <>
              <p>联系我们</p>
            </>
          ),
        },
        childWrapper: {
          children: [
            {
              href: 'http://www.hopechina.cc/pages/aboutus/coreteam.html',
              name: 'link0',
              children: (
                <>
                  <p>核心团队</p>
                </>
              ),
              target: '_blank',
            },
            {
              href: 'http://www.hopechina.cc/pages/aboutus/joinus.html',
              name: 'link1',
              children: (
                <>
                  <p>加入我们</p>
                </>
              ),
              target: '_blank',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <p>
          <span>©</span>2020 - 至今&nbsp;<span>&nbsp;by WISSO&nbsp;</span>{' '}
          深圳市威騪智能科技有限公司
        </p>
      </>
    ),
  },
};
