import React from 'react';

export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>互播课堂</p>
          </>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <>
            <p>手机客户端下载</p>
          </>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content k7ecwnqnbr-editor_css',
        children: (
          <>
            <p>最低支持Android系统版本4.1+</p>
            <p>最低支持iOS版本：iOS 10.0+</p>
          </>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href:
            'http://fir.wisso.cn/uyd3',
          className: 'banner5-button',
          type: 'primary',
          children: (
            <>
              <p>Android 客户端</p>
            </>
          ),
          target: '_blank',
        },
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          children: (
            <>
              <p>iOS  客户端</p>
            </>
          ),
          className: 'banner5-button',
          href:
            'http://fir.wisso.cn/n1ct',
          type: 'primary',
          target: '_blank',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/cover-appdownload.png',
  },
};
