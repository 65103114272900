import React from 'react';

export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>互播课堂</p>
          </>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <>
            <p>Windows/Mac电脑客户端</p>
          </>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content k7ecwnqnbr-editor_css',
        children: (
          <>
            <p>Windows 系统需要 Windows7及以上操作系统</p>
            <p>Mac 系统暂无特殊要求</p>
          </>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href:
            'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/client/WISSO%20Interactive%20ClassRoom%20Setup%201.0.1.exe',
          className: 'banner5-button',
          type: 'primary',
          children: (
            <>
              <p>Windows客户端</p>
            </>
          ),
          target: '',
        },
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          children: (
            <>
              <p>MacOS 客户端</p>
            </>
          ),
          className: 'banner5-button',
          href:
            'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/client/WISSO%20Interactive%20ClassRoom-1.0.1.dmg',
          type: 'primary',
          target: '',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/cover-download.png',
  },
};
