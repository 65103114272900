import React, { Component } from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './Home/Nav3';
import Footer from './Home/Footer1';
import Home from './Home';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import wxShareTool from './weixin_share'
import SuspendButton from 'suspend-button'

import {
  Nav30DataSource,
  Footer10DataSource,
} from './Home/data.source.js';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {

    wxShareTool({
                  sharelink_title: '互播课堂',
                  sharelink_desc: '线上课堂系统解决方案，为教育机构与老师赋能',
                  sharelink_link: window.location.href,
                  sharelink_img: 'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/sharelogo.png',
                  sharelink_appimg:'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/sharelogo.png',
              });
    // 适配手机屏幕
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  testProduct(){
    window.location.href = 'https://r.xiumi.us/stage/v5/2Wv8U/197237249';
  }

  render() {
    return (
      <Router>
        <div>
          <Header dataSource={Nav30DataSource} isMobile={this.state.isMobile} />
          <Route exact path="/" component={Home} />
          <Route path="/function" component={Page2} />
          <Route path="/pcdownload" component={Page3} />
          <Route path="/appdownload" component={Page4} />
          <Footer dataSource={Footer10DataSource} isMobile={this.state.isMobile} />

          {this.state.isMobile ? <SuspendButton img = "https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/floatbtn.png" onClick={() => {
            this.testProduct();
          }}>
             </SuspendButton>:""}
        </div>
      </Router>
    );
  }
}

export default App;
