import wx from 'weixin-js-sdk'
import Fetch from 'isomorphic-fetch';
import Hashes from 'jshashes';

export default wxShareTool =>{let domDesc = document.getElementsByTagName('meta')['description']
    let domDescContent = domDesc ? domDesc.content : null
    let shareObj = {
        sharelink_title: wxShareTool.sharelink_title || document.title,
        sharelink_desc: wxShareTool.sharelink_desc || domDescContent || document.title,
        sharelink_link: wxShareTool.sharelink_link || window.location.href,
        sharelink_img: wxShareTool.sharelink_img || 'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/sharelogo.png',
        sharelink_appimg: wxShareTool.sharelink_appimg || 'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/sharelogo.png',
        tickets:"",
    }
    // let signature = getSingature(tickets)
    // setupWXShare(signature,shareObj.sharelink_title,shareObj.sharelink_desc,shareObj.sharelink_img);

    // let tickets = "";
    Fetch('http://www.upedu.cc/wechatshar/getAccessToken?jsonAccessToken=?')
        .then(function(response) {
            response.text().then(function(data) {
                // data就是我们请求的repos
                // "jsonAccessToken({"ticket":"kgt8ON7yVITDhtdwci0qedb6T_TKv1GmgoDXETrWF97xWgLR02dmCok68ePq8nvCAj4oMXTuEXm35HGmz9V9MA"})"
                console.log(data)
                let tickets =data.substring(27,data.length-3);
                console.log(tickets)
                let signature = getSingature(tickets)
                setupWXShare(signature,shareObj.sharelink_title,shareObj.sharelink_desc,shareObj.sharelink_img);

            });
        }).catch(err=>{
            // 异常处理
        console.log(err)
        });
}

const siteWxConfig = {
    timestamp: getTimeStamp(), // 必填，生成签名的时间戳
    nonceStr: getNonceStr(), // 必填，生成签名的随机串
}

export function getTimeStamp() {
    let time =new Date();
    let ts = time.getTime();
    return ts;
}

export function getNonceStr() {
    const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    const maxPos = $chars.length;
    let nonceStr = '';
    for (let i = 0; i < 32; i++) {
        nonceStr += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return nonceStr;
}

export function getSingature(tickets) {
    const url = window.location.href;
    console.log(siteWxConfig.nonceStr+" "+siteWxConfig.timestamp+" "+ url)
    let signature='jsapi_ticket='+tickets+'&noncestr='+siteWxConfig.nonceStr+'&timestamp='+siteWxConfig.timestamp+'&url='+url;
    console.log(signature);
    let SHA1 = new Hashes.SHA1();
    let sign = SHA1.hex(signature);
    return sign;
}

export function getTickets(){
    Fetch('http://www.upedu.cc/wechatshar/getAccessToken?jsonAccessToken=?')
        .then(data=>{
            // 业务代码
            console(data);
            return data;
        })
        .catch(err=>{
            // 异常处理
        })
}

export function setupWXShare(signature,sharelink_title,sharelink_desc,sharelink_img) {
    wx.config({
        debug : false ,
        appId : 'wx00c818534596aa32' , // 必填，公众号的唯一标识
        timestamp : siteWxConfig.timestamp , // 必填，生成签名的时间戳
        nonceStr : siteWxConfig.nonceStr , // 必填，生成签名的随机串
        signature : signature ,// 必填，签名
        jsApiList : [
            'onMenuShareTimeline' ,
            'onMenuShareAppMessage' ,
            'onMenuShareQQ' ,
            'onMenuShareWeibo' ,
            'onMenuShareQZone' ,
        ]
    });

    console.log(wx.config)
    wx.ready(function () {
        //分享到朋友圈
        wx.onMenuShareTimeline({
            title : sharelink_title , // 分享标题
            link : window.location.href , // 分享链接
            imgUrl : sharelink_img , // 分享图标
            success : function () {
                // 用户确认分享后执行的回调函数
            } ,
            cancel : function () {
                // 用户取消分享后执行的回调函数
            }
        });
        //分享给朋友
        wx.onMenuShareAppMessage({
            title : sharelink_title , // 分享标题
            desc : sharelink_desc , // 分享描述
            link : window.location.href , // 分享链接
            imgUrl : sharelink_img , // 分享图标
            type : '' , // 分享类型,music、video或link，不填默认为link
            dataUrl : '' , // 如果type是music或video，则要提供数据链接，默认为空
            success : function () {
                // 用户确认分享后执行的回调函数
            } ,
            cancel : function () {
                // 用户取消分享后执行的回调函数
            }
        });
        //分享到qq
        wx.onMenuShareQQ({
            title : sharelink_title , // 分享标题
            desc : sharelink_desc , // 分享描述
            link : window.location.href , // 分享链接
            imgUrl : sharelink_img , // 分享图标
            type : '' , // 分享类型,music、video或link，不填默认为link
            dataUrl : '' , // 如果type是music或video，则要提供数据链接，默认为空
            success : function () {
                // 用户确认分享后执行的回调函数
            } ,
            cancel : function () {
                // 用户取消分享后执行的回调函数
            }
        })
        //分享到微博
        wx.onMenuShareWeibo({
            title : sharelink_title , // 分享标题
            desc : sharelink_desc , // 分享描述
            link : window.location.href , // 分享链接
            imgUrl : sharelink_img , // 分享图标
            type : '' , // 分享类型,music、video或link，不填默认为link
            dataUrl : '' , // 如果type是music或video，则要提供数据链接，默认为空
            success : function () {
                // 用户确认分享后执行的回调函数
            } ,
            cancel : function () {
                // 用户取消分享后执行的回调函数
            }
        })
        //分享到qq空间
        wx.onMenuShareQZone({
            title : sharelink_title , // 分享标题
            desc : sharelink_desc , // 分享描述
            link : window.location.href , // 分享链接
            imgUrl : sharelink_img , // 分享图标
            type : '' , // 分享类型,music、video或link，不填默认为link
            dataUrl : '' , // 如果type是music或video，则要提供数据链接，默认为空
            success : function () {
                // 用户确认分享后执行的回调函数
            } ,
            cancel : function () {
                // 用户取消分享后执行的回调函数
            }
        })
    });
}
function encodeUTF8 (s) {

    const code = encodeURIComponent(s)

    const bytes = []

    for (var i = 0; i < code.length; i++) {

        const c = code.charAt(i)

        if (c === '%') {

            const hex = code.charAt(i + 1) + code.charAt(i + 2)

            const hexVal = parseInt(hex, 16)

            bytes.push(hexVal)

            i += 2

        } else bytes.push(c.charCodeAt(0))

    }

    return bytes

}

// var ts = new Date().getTime();
// const ua = window.navigator.userAgent.toLowerCase()
// // if (ua.indexOf('micromessenger') < 0) return false
// // 最好在在 router 的全局钩子里调用这个方法，每次页面的 URL 发生变化时，都需要重新获取微信分享参数
// // 如果你的 router 使用的是 hash 形式，应该不用每次都重新获取微信分享参数
// // 一下wx_config可以通过接口调用获取微信配置参数
// // api.get('wechat_sdk', { url: document.location.href.split('#')[0] }, r => {
// // })
// wx.config({
//   // debug: flase, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//   appId: 'wx00c818534596aa32', // 必填，公众号的唯一标识
//   timestamp: ts, // 必填，生成签名的时间戳
//   nonceStr: nonceStr, // 必填，生成签名的随机串
//   signature:
//       // "",
// // {
// //
//     'jsapi_ticket='+getTickets()+'&noncestr='+noncestr+'&timestamp='+ts+'&url='+window.location.href,
//     // 'db4f9cf55c862cdf984d219435cfb611528c6ae5',// 必填，签名，见附录1
//   jsApiList: [
//     'onMenuShareAppMessage',
//     'onMenuShareTimeline',
//     // 'onMenuShareQQ'
//   ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
// })
//
// wx.ready(() => {
//   //分享给朋友
//   wx.onMenuShareAppMessage({
//     title: obj.title, // 分享标题
//     desc: obj.desc, // 分享描述
//     link: obj.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//     imgUrl: obj.imgUrl, // 分享图标
//     type: '', // 分享类型,music、video或link，不填默认为link
//     dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
//     success: function () {
//       // 用户确认分享后执行的回调函数
//       wxsharetool.success()
//     }
//   })
//   //分享到朋友圈
//   wx.onMenuShareTimeline({
//     title: obj.title, // 分享标题
//     link: obj.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//     imgUrl: obj.imgUrl, // 分享图标
//     success: function () {
//       // 用户确认分享后执行的回调函数
//     },
//     cancel: function () {
//       // 用户取消分享后执行的回调函数
//     }
//   })
//   // wx.onMenuShareQQ({
//   //   title: obj.title, // 分享标题
//   //   desc: obj.desc, // 分享描述
//   //   link: obj.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//   //   imgUrl: obj.imgUrl, // 分享图标
//   //   success: function () {
//   //     // 设置成功
//   //   }
//   // })
// })

// export default wxShareTool => {
//   let domDesc = document.getElementsByTagName('meta')['description']
//   let domDescContent = domDesc ? domDesc.content : null
//   // let shareObj = {
//   //   title: wxShareTool.title || document.title,
//   //   desc: wxShareTool.desc || domDescContent || document.title,
//   //   link: wxShareTool.link || window.location.href,
//   //   imgUrl: wxShareTool.imgUrl || 'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/sharelogo.png',
//   //   appimgUrl: wxShareTool.imgUrl || 'https://wisso-hubo-education.oss-cn-beijing.aliyuncs.com/huboedusite/sharelogo.png'
//   // }
//   const siteWxConfig = {
//       timestamp: new Date().getTime(), // 必填，生成签名的时间戳
//       nonceStr: getNoceStr(), // 必填，生成签名的随机串
//   }
//
// // }
// function sha1 (s) {
//
//     let data = new Uint8Array(encodeUTF8(s))
//
//     let i, j, t
//
//     let l = ((data.length + 8) >>> 6 << 4) + 16
//
//     let sa = new Uint8Array(l << 2)
//
//     sa.set(new Uint8Array(data.buffer))
//
//     sa = new Uint32Array(sa.buffer)
//
//     for (t = new DataView(sa.buffer), i = 0; i < l; i++) sa[i] = t.getUint32(i << 2)
//
//     sa[data.length >> 2] |= 0x80 << (24 - (data.length & 3) * 8)
//
//     sa[l - 1] = data.length << 3
//
//     let w = []
//
//     let f = [
//
//         function () {
//
//             return m[1] & m[2] | ~m[1] & m[3]
//
//         },
//
//         function () {
//
//             return m[1] ^ m[2] ^ m[3]
//
//         },
//
//         function () {
//
//             return m[1] & m[2] | m[1] & m[3] | m[2] & m[3]
//
//         },
//
//         function () {
//
//             return m[1] ^ m[2] ^ m[3]
//
//         }
//
//     ]
//
//     let rol = function (n, c) {
//
//         return n << c | n >>> (32 - c)
//
//     }
//
//     let k = [1518500249, 1859775393, -1894007588, -899497514]
//
//     let m = [1732584193, -271733879, null, null, -1009589776]
//
//     m[2] = ~m[0]
//
//     m[3] = ~m[1]
//
//     for (i = 0; i < s.length; i += 16) {
//
//         let o = m.slice(0)
//
//         for (j = 0; j < 80; j++) {
//
//             w[j] = j < 16 ? s[i + j] : rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1)
//
//             t = rol(m[0], 5) + f[j / 20 | 0]() + m[4] + w[j] + k[j / 20 | 0] | 0
//
//             m[1] = rol(m[1], 30)
//
//             m.pop()
//
//             m.unshift(t)
//
//         }
//
//         for (j = 0; j < 5; j++) m[j] = m[j] + o[j] | 0
//
//     }
//
//     t = new DataView(new Uint32Array(m).buffer)
//
//     for (let i = 0; i < 5; i++) m[i] = t.getUint32(i << 2)
//
//
//
//     let hex = Array.prototype.map.call(new Uint8Array(new Uint32Array(m).buffer), function (e) {
//
//         return (e < 16 ? '0' : '') + e.toString(16)
//
//     }).join('')
//
//     return hex
//
// }
